import {
  mobile,
  backend,
  creator,
  web,
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  git,
  figma,
  hoick,
  isi,
  ieee,
  mv,
  pr,
  skill,
  upraised,
  fav,
  hacktrix,
  ieeeweb,
  machine,
  jsw,
  frontend,
  covid,
  viterbi,
  equinix,
  portfolio,
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Web Developer",
    icon: web,
  },
  {
    title: "React Native Developer",
    icon: mobile,
  },
  {
    title: "Backend Developer",
    icon: backend,
  },
  {
    title: "Content Creator",
    icon: creator,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },

  {
    name: "git",
    icon: git,
  },
  {
    name: "figma",
    icon: figma,
  },
];

const experiences = [
  {
    title: "Fabric Product Management Intern",
    company_name: "Equinix",
    icon: equinix,
    iconBg: "#383E56",
    date: "June 2023 - August 2023",
    points: [
      "Conducted a root cause analysis of the existing explore page, identifying issues with the current search functionality.",
      " Developed a functional micro-frontend for a new search engine, set to replace the current one across four critical portal sections.",
      "Analyzed customer exposure to the functionality, aiming to increase long-term sales by 2-5% for a tool generating nearly $40 million in quarterly revenues.",
    ],
  },
  {
    title: "Viterbi Intern",
    company_name:
      "University of Southern California's Viterbi School of Engineering",
    icon: viterbi,
    iconBg: "#383E56",
    date: "May 2023 - August 2023",
    points: [
      "Redesigned 60+ web pages for all the programs offered by the engineering school using Figma, resulting in a visually appealing and intuitive design.",
      "Developed and maintained the website utilizing WordPress (Cornerstone), ensuring optimal performance and functionality of the pages with over 1 million yearly visitors.",
    ],
  },
  {
    title: "Research Assistant",
    company_name:
      "University of Southern California - Information Sciences Institute (USC ISI)",
    icon: isi,
    iconBg: "#383E56",
    date: "November 2022 - April 2023",
    points: [
      "Designed, and prototyped the DataFest web app utilizing Figma, resulting in a user-friendly and visually appealing platform.",
      "Proactively developed and maintained the website utilizing WordPress, ensuring optimal performance and functionality.",
      "Implemented new plug-ins, enhancing the website's usability and interactivity, resulting in a 25% MoM increase in participation.",
    ],
  },
  {
    title: "Product Analyst Intern",
    company_name: "Upraised",
    icon: upraised,
    iconBg: "#383E56",
    date: "January 2022 - May 2022",
    points: [
      "Analyzed data to create Retool dashboards for product evaluation and forecasting, providing insights to drive business growth.",
      "Utilized analytics tools, including Mixpanel and Hotjar, to design effective funnels and increase conversions by over 12%.",
      "Presented bi-weekly reports in team meetings, effectively communicating key metrics and driving informed decision-making.",
    ],
  },
  {
    title: "Associate Product Management Intern",
    company_name: "Upraised",
    icon: upraised,
    iconBg: "#383E56",
    date: "July 2021 - December 2021",
    points: [
      "Automated user feedback delivery through Slack APIs, resulting in 80% faster responses and improved customer satisfaction.",
      "Created PM-related activities, solutions, and technical content to simplify complex concepts for non-technical stakeholders.",
      "Assisted 50+ Upraised CAP users in their journey to become Product Managers, resulting in a high success rate.",
    ],
  },
  {
    title: "Web Development Team Lead",
    company_name: "Hoick (non-profit)",
    icon: hoick,
    iconBg: "#383E56",
    date: "June 2021 - March 2022",
    points: [
      "Led the migration of the company website to a fully functional web app, resulting in an improved user experience.",
      "Recruited and managed a team of 4 full-stack developers and 2 UI designers, resulting in a cohesive and productive team.",
      "Coordinated regular stand-up meetings to test and deploy frontend architecture, ensuring smooth operations.",
    ],
  },
  {
    title: "Web & App Dev Domain Founding Member and WebMaster (Head)",
    company_name: "IEEE SRM University, Kattankulathur",
    icon: ieee,
    iconBg: "#383E56",
    date: "July 2020 - May 2022",
    points: [
      "Website development for IEEE and various other events",
      "Mentoring students and fresher's for skill upliftment",
      "Working on external projects on a pro-bono basis.",
      "Joined as a technical lead, promoted to Secretary and then to the WebMaster, the highest post available.",
    ],
  },
  {
    title: "Technical Product Management Intern",
    company_name: "PowerRouter",
    icon: pr,
    iconBg: "#383E56",
    date: "January 2021 - April 2021",
    points: [
      "Shipped 4 significant features to increase lead assignment and account matching speeds on Salesforce by 20%.",
      "Conducted product research and designed wireframes to improve usability, resulting in increased product adoption.",
      "Executed a successful Product Hunt launch, hunted by YC founders, resulting in increased visibility and user acquisition.",
    ],
  },
  {
    title: "React.js Development Intern",
    company_name: "MekPark",
    icon: mv,
    iconBg: "#383E56",
    date: "September 2020 - November 2020",
    points: [
      "Reimplemented the MekVahan user dashboards using ReactJS libraries, resulting in a more intuitive and user-friendly interface.",
      "Optimized and reduced web app load time by 0.5 seconds, improving user engagement and satisfaction.",
      "Implemented integration tests and improved overall test coverage using Jest, ensuring a stable and reliable product.",
    ],
  },
  {
    title: "Front-end Web Development Intern",
    company_name: "Skill Inc.",
    icon: skill,
    iconBg: "#383E56",
    date: "July 2020 - August 2020",
    points: [
      "Prototyped the UI of an early-stage e-learning platform using FIgma to translate wireframes and mockups into an interactive UI.",
      "Implemented the platform's front-end interface, ensuring responsiveness, accessibility and user friendliness.",
      "Integrated the front-end with a Firebase back-end system to make the platform functional, secure, and in sync with the database.",
    ],
  },
  {
    title: "Miscellaneous",
    company_name: "Personal co-curricular pursuits",
    icon: fav,
    iconBg: "#383E56",
    date: "July 2018 - Present",
    points: [
      "Associate ML Researcher at Next Tech's McCarthy Lab - India's first student-run research lab.",
      "Open-source mentor and supervisor at Girlscript Summer of Code 2021.",
      "Co-founder and community executive at SRM Machine Intelligence Community - first such community in the southern hemisphere.",
      "Organizing Committee Member and In-house mentor for HackTrix 2021.",
      "Open-source mentor at LetsGrowMore Summer of Code 2021",
      "Open-source mentor at Script Winter of Code 2020",
      "Open-source mentor at Devscript Winter of Code 2020",
      "Open-source mentor at Sanscript Autumn of Code 2020",
      "Open-source mentor at Hackin Codes Contributor's Hack 2020",
      "Open-source contributor at Student Code-in 2020",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "HackTrix Website",
    description:
      "A full-stack website for IEEE SRMIST SB's yearly hackathon HackTrix.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mern",
        color: "green-text-gradient",
      },
    ],
    image: hacktrix,
    source_code_link:
      "https://hacktrix-2022-6h755xyoc-shauryabhandaris-projects.vercel.app/",
  },
  {
    name: "Personal Portfolio V1",
    description: "The first version of my personal portfolio.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mern",
        color: "green-text-gradient",
      },
    ],
    image: portfolio,
    source_code_link:
      "https://6530243e22a7333f26409742--sage-conkies-a3a451.netlify.app/",
  },
  // {
  //   name: "IEEE Website",
  //   description: "A full-stack website for IEEE SRMIST SB. ",
  //   tags: [
  //     {
  //       name: "react",
  //       color: "blue-text-gradient",
  //     },
  //     {
  //       name: "mern",
  //       color: "green-text-gradient",
  //     },
  //   ],
  //   image: ieeeweb,
  //   source_code_link: "https://www.ieeesrmist.in/",
  // },
  {
    name: "ML Algorithms",
    description:
      "From scratch implementation of supervised and unsupervised ML algorithms with detailed theory reports. ",
    tags: [
      {
        name: "python",
        color: "blue-text-gradient",
      },
      {
        name: "machinelearning",
        color: "green-text-gradient",
      },
    ],
    image: machine,
    source_code_link: "https://github.com/ShauryaBhandari/Machine-Learning",
  },
  {
    name: "JavaScript Widgets",
    description:
      "A collection of 30+ JS widgets made using only Vanilla JS and HTML CSS.",
    tags: [
      {
        name: "javascript",
        color: "blue-text-gradient",
      },
      {
        name: "machinelearning",
        color: "green-text-gradient",
      },
    ],
    image: jsw,
    source_code_link: "https://github.com/ShauryaBhandari/Vanilla-JS-Projects",
  },
  {
    name: "Website Templates",
    description:
      "A collection of 20+ website templates made using HTML, CSS, and Vanilla JS.",
    tags: [
      {
        name: "javascript",
        color: "blue-text-gradient",
      },
      {
        name: "machinelearning",
        color: "green-text-gradient",
      },
    ],
    image: frontend,
    source_code_link: "https://github.com/ShauryaBhandari/Website-Templates",
  },
  {
    name: "Social Distancing Monitor",
    description:
      "A comprehensive ML solution for detecting and monitoring social distancing norms. Currently undergoing patenting process.",
    tags: [
      {
        name: "javascript",
        color: "blue-text-gradient",
      },
      {
        name: "machinelearning",
        color: "green-text-gradient",
      },
    ],
    image: covid,
    source_code_link: "https://github.com/ShauryaBhandari/Major-Project",
  },
];

export { services, technologies, experiences, testimonials, projects };
