import React from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";

import { styles } from "../styles";
import { link } from "../assets";
import { SectionWrapper } from "../hoc";
import { projects } from "../constants";
import { fadeIn, textVariant } from "../utils/motion";

const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  source_code_link,
}) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        className="bg-tertiary p-5 rounded-2xl
                sm:w-[360px] w-full h-[435px]"
      >
        <div className="relative w-full h-[200px]">
          <img
            src={image}
            alt={name}
            className="object-cover rounded-2xl w-full h-[160px]"
          />

          <div className="absolute inset-0 flex justify-end m-3 card-img_hover">
            <div
              onClick={() => window.open(source_code_link, "_blank")}
              className="bg-white w-10 h-10
                            rounded-full flex justify-center
                            items-center cursor-pointer"
            >
              <img
                src={link}
                alt="github"
                className="w-1/2 h-1/2 object-contain"
              />
            </div>
          </div>
        </div>

        <div className="mt-">
          <h3 className="text-white font-bold text-[24px]">{name}</h3>
          <p className="mt-2 text-secondary text-[16px]">{description}</p>
        </div>

        {/* <div className="mt-auto pt-2 flex  gap-2  w-full bottom-0">
          {tags.map((tag) => (
            <p key={tag.name} className={`text-[14px] ${tag.color}`}>
              #{tag.name}
            </p>
          ))}
        </div> */}
      </Tilt>
    </motion.div>
  );
};

export const Projects = () => {
  return (
    <div className="m-auto">
      <motion.div variants={textVariant()}>
        {/* <p className={`${styles.sectionSubText}`} style={{ "padding-left": 0, "list-style": "none", "font-size": "30px", "margin-bottom": "7px", "margin-top": "0px", "text-align": "center" }}>My Work</p> */}
        <h2
          className={`${styles.sectionHeadText}`}
          style={{
            "padding-left": 0,
            "list-style": "none",
            "font-size": "4.5rem",
            "margin-bottom": "7px",
            "margin-top": "0px",
            "text-align": "center",
          }}
        >
          Projects
        </h2>
      </motion.div>

      <div className="w-full flex flex-wrap justify-center">
        <motion.p
          variants={fadeIn("", "", 0.1, 1)}
          className="mt-3 text-secondary justify-text-center text-[18px] max-w-3xl leading-[30px]"
        >
          The following projects showcase my skills and experience in solving
          real-world problems. Each project description is brief and includes
          links to the corresponding code repositories. These projects
          demonstrate my ability to tackle complex problems, quickly learn and
          adapt to new technologies, and refactor code for improved performance
          and functionality.
        </motion.p>
      </div>

      <div className="mt-1 flex flex-wrap gap-7 justify-center">
        {projects.map((project, index) => (
          <ProjectCard key={`project-${index}`} index={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(Projects, "");
