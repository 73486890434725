import emoji from "react-easy-emoji";

const skillsSection = {
  skills: [],
  softwareSkills: [
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python fa-xl",
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js fa-xl",
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node fa-xl",
    },
    {
      skillName: "HTML",
      fontAwesomeClassname: "fab fa-html5 fa-xl",
    },
    {
      skillName: "CSS",
      fontAwesomeClassname: "fab fa-css3 fa-xl",
    },

    {
      skillName: "React",
      fontAwesomeClassname: "fab fa-react fa-xl",
    },
    {
      skillName: "MySQL",
      fontAwesomeClassname: "fas fa-database fa-xl",
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "fa-brands fa-figma fa-xl",
    },
    {
      skillName: "HotJar",
      fontAwesomeClassname: "fa-brands fa-hotjar fa-xl",
    },

    {
      skillName: "Git",
      fontAwesomeClassname: "fab fa-git fa-xl",
    },
    {
      skillName: "GitHub",
      fontAwesomeClassname: "fa-brands fa-github fa-xl",
    },
    {
      skillName: "WordPress",
      fontAwesomeClassname: "fa-brands fa-wordpress fa-xl",
    },
  ],
  display: true,
};

export { skillsSection };
