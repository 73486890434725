import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../assets/img/header-img.svg";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = ["A Web Developer", "An Aspiring PM"];
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => clearInterval(ticker);
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <h1>
              {`I'm Shaurya Bhandari `}
              <span className="wrap">{text}</span>
            </h1>
            <br></br>
            <p className="text-white">
              <ul style={{ type: "circle" }}>
                <li>
                  🚀 I am Shaurya Bhandari, a self-taught full-stack web
                  developer and an aspiring Product Manager.
                </li>
                <br />
                <li>
                  🚀 I like to think of myself as a "Hybrid Developer." In
                  short, I enjoy being both creative + technical. Product
                  Management allows me to be both.
                </li>
                <br />
                <li>
                  🚀 I specialize in front-end technologies creating bespoke
                  sites, web components and experiences focused on usability and
                  user experience.
                </li>
                <br />
                <li>
                  🚀 I graduated with a Bachelor's in Technology in Computer
                  Science and Engineering from SRM Institute of Science and
                  Technology.
                </li>
                <br />
                <li>
                  🚀 I am currently a full-time MS in Computer Science student
                  at {""}
                  <b>
                    University of Southern California's Viterbi School of
                    Engineering.
                  </b>
                </li>
                <br />
              </ul>
              <button
                onClick={() => {
                  window.open("https://www.linkedin.com/in/shauryabhandari/");
                }}
              >
                Let's Connect! <ArrowRightCircle size={25} />
              </button>
            </p>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Header Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
