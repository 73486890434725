import { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import logo from "../assets/img/logo_sb.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import github from "../assets/img/github.svg";
import notion from "../assets/img/notion.svg";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      window.scrollY > 50 ? setScrolled(true) : setScrolled(false);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            <Nav.Link
              href="#home"
              className={
                activeLink === "home" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={
                activeLink === "skills" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("skills")}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#blog"
              className={
                activeLink === "blog" ? "active navbar-link" : "navbar-link"
              }
              onClick={() =>
                window.open(
                  "https://iq.opengenus.org/how-i-mastered-ml-as-fresher/"
                )
              }
            >
              Blog
            </Nav.Link>
            <Nav.Link
              href="#contact"
              className={
                activeLink === "contact" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => window.scrollTo(0, document.body.scrollHeight)}
            >
              Contact
            </Nav.Link>
          </Nav>
          <Button
            variant="primary"
            href="https://docs.google.com/document/d/13b8Gv0ApGBm-9epSI1v6uirHGipQs12X/edit?usp=sharing&ouid=116787201458689106823&rtpof=true&sd=true"
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            &nbsp;View Resume
          </Button>
          <span className="navbar-text">
            <div className="social-icon">
              <a
                href="https://www.linkedin.com/in/shauryabhandari/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={navIcon1} alt="LinkedIn" />
              </a>
              <a
                href="https://github.com/ShauryaBhandari"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={github} alt="GitHub" />
              </a>
              <a
                href="https://shauryabhandari.notion.site/Hey-I-m-Shaurya-Bhandari-455e0c84145e49fe91ea081f745c10ee?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={notion} alt="Twitter" />
              </a>
            </div>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
